var config = {};
config.env = 'test';

//AWS
config.aws = {};
config.aws.apiRoot = "https://test-api-gims.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'QK544G3StV3nG1NRdfhEG1e54RIi5ppT30zmUmkp';

export default config;
